.advanced-search-options {
	display: none;
}

.list-alpha {
	overflow: hidden;

	.advanced-search-options & {
		padding-top: $content-section-padding;
	}

	> li {
		float: left;
		width: 3.8462%;
		text-align: center;

		@include until($phone-wide) {
			width: 7.5%;
		}
	}

	.list-alpha-link {
		color: $black;
		font-family: $font-brand;
		font-weight: 400;

		&:hover {
			color: $nav-link-hover;
		}
	}

	.inactive {
		.list-alpha-link {
			color: darken($color-accent-grey, 20%);
			cursor: text;
		}
	}
}

$dropdown-spacing: 20px;

.dropdowns {
	position: relative;
	margin-left: -15px;
	margin-right: -4px;
	padding-left: 4px;

	.select-dropdown {
		@extend %inline-block;
		margin-left: -4px;
		width: 33.3333%;
		border-left: 15px solid transparent;
		margin-top: $dropdown-spacing;

		@include until($phone-wide) {
			margin-left: 0;
			width: 100%;
		}
	}
}

.filter-accordion {
	@extend %inline-block;

	width: 33.3333%;
	border-left: 15px solid transparent;
	margin-left: -4px;
	margin-top: $dropdown-spacing;
	vertical-align: top;

	@include until($phone-wide) {
		width: 100%;
		margin-left: 0;
	}

	&:nth-child(3n + 2) {
		.filter-accordion-list {
			margin-left: calc(-100% - 30px);
		}
	}

	&:nth-child(3n + 3) {
		.filter-accordion-list {
			margin-left: calc(-200% - 48px);
		}
	}

	@include until($phone-wide) {
		&:nth-child(3n + 2),
		&:nth-child(3n + 3) {
			.filter-accordion-list {
				margin-left: 0;
				width: 100%;
			}
		}
	}
}

.filter-accordion-list {
	display: none;
	float: left;
	width: calc(300% + 48px);
	margin-left: -15px;

	@include until($phone-wide) {
		width: 100%;
		float: none;
		border-left: 0 none;
		margin-left: 0;
	}

	> li {
		float: left;
		width: 33.33334%;
		border-left: 15px solid transparent;
		margin-top: $dropdown-spacing;

		&:nth-child(3n + 1) {
			clear: left;
		}

		@include until($phone-wide) {
			width: 100%;
			float: none;
			border-left: 0 none;
		}
	}
}

.filter-accordion-link {
	@extend %uc;

	display: inline-block;
	font: 400 13px/1.4 $font-brand;
	padding: 10px 30px 10px 15px;
	background-color: $bg-filter-tag;
	width: 100%;
	text-align: left;
	color: $color-brand-white;
	position: relative;

	&:hover {
		background-color: $bg-filter-tag-hover;
	}
}

.filter-accordion-link {
	.fa {
		opacity: 0;
		position: absolute;
		right: 15px;
		transform: rotate(45deg);
		font-size: 18px;
	}

	&.is-selected {
		.fa {
			opacity: 1;
		}
	}
}

.filter-options {
	padding-left: 4px;
	margin-left: -15px;
	margin-right: -5px;
}

.filter-accordion-btn {
	@extend %uc;

	background-color: $color-brand-white;
	border: 1px solid $border-inputs;
	color: $black;
	font: 400 13px/1.4 $font-brand;
	padding: 10px 15px;
	transition: border 0.2s ease-in;
	text-align: left;
	position: relative;
	display: block;
	width: 100%;

	&:hover,
	&.is-active-menu {
		border-color: darken($border-inputs, 10%);

		.custom-icon {
			color: $nav-link-hover;
		}
	}

	&.is-active-menu {
		.custom-icon {
			transform: rotate(45deg);
		}
	}

	.custom-icon {
		position: absolute;
		right: 10px;
		transition: color 0.2s ease-in, transform 0.3s ease-in;
	}
}
